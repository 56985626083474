import * as React from "react"
import Img, { GatsbyImageFixedProps } from "gatsby-image"

const BookItem = ({ book }: { book: BookItemProps }) => (
  <div className="br3 ba dark-gray b--black-10 mv4 w-100 w-50-m w-25-l mw5 center">
    <a
      className="link flex flex-column content-center near-black hover-light-blue"
      href={`/books${book.fields.slug}`}
    >
      {book.frontmatter.cover && (
        <figure className="center">
          <Img
            className="center br3 br--top"
            fixed={book.frontmatter.cover.childImageSharp.fixed}
            alt={`Cover for "${book.frontmatter.title}"`}
          />
          <br />
          <caption className="dn">
            Cover for <em className="i">{book.frontmatter.title}</em>
          </caption>
        </figure>
      )}
      <div className="pa2 ph3-ns pb3-ns">
        <hgroup className="flex flex-column justify-around w-100 mt1 sans-serif">
          <h1 className="f6 f5-ns tc mv0">{book.frontmatter.title}</h1>
          {book.frontmatter.price && (
            <h2 className="f6 f5-ns mt2 flex flex-row w-two-thirds-ns center justify-between">
              <abbr title="suggested retail price">SRP</abbr>
              <span>${book.frontmatter.price}</span>
            </h2>
          )}
        </hgroup>
        <p className="f6 lh-copy measure near-black">{book.excerpt}</p>
      </div>
    </a>
  </div>
)

export interface BookItemProps {
  excerpt: string
  frontmatter: {
    outboundLink?: string
    title: string
    price?: string
    cover?: { childImageSharp: GatsbyImageFixedProps }
  }
  fields: { slug: string }
}

export default BookItem
