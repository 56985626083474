import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Book, { BookItemProps } from "../components/book-item"

const BooksPage = ({ data }: BooksPageProps) => (
  <Layout>
    <SEO title="Books by Gabrielle" />
    <div className="w-100 w-80-ns center">
      <h1 className="tc">Books by Gabrielle</h1>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <Book key={node.id} book={node} />
      ))}
    </div>
  </Layout>
)

export default BooksPage

interface BooksPageProps {
  data: {
    allMarkdownRemark: {
      edges: readonly { node: BookItemProps & { id: number } }[]
    }
  }
}

export const fixedThumb = graphql`
  fragment fixedThumb on File {
    childImageSharp {
      fixed(height: 250) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            cover {
              ...fixedThumb
            }
            price
            isbn
          }
          excerpt
        }
      }
    }
  }
`
